import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ ]

  initialize () {

  }

  openMainDropdown() {
    this.hideUserMenu();

    if ($('#main-dropdown').hasClass("active") == true) {
      this.hideMainDropdown();
    } else {
      this.showMainDropdown();
    }

  }

  openUserMenu() {
    this.hideMainDropdown();

    if ($("#header .user").hasClass("active") == true) {
      this.hideUserMenu();
    } else {
      this.showUserMenu();
    }

  }

  hideMainDropdown() {
    $("#header .menu-button").removeClass("active");
    $("#header").removeClass("dropdown-opened");
    $("#main-dropdown").removeClass("active");
  }

  showMainDropdown() {
    $("#header .menu-button").addClass("active");
    $("#header").addClass("dropdown-opened");
    $("#main-dropdown").addClass("active");
  }
  
  hideUserMenu() {
    $("#header .user").removeClass("active");
    $("#header .user .menu-dropdown").removeClass("active");
  }

  showUserMenu() {
    $("#header .user").addClass("active");
    $("#header .user .menu-dropdown").addClass("active");
  }
  
}

